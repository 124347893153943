import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { PortalProgressionFragment } from '../../../graphql/generated';
import { useFeature } from '../../../utils/feature-flags';
import { AnyObject } from '../../hooks/use-record-state';
import { useFormContext } from '../form-data-provider';
import { usePortalProgressionStore } from '../portal-progression-provider';
import {
  HandleSetPortalProgressionOptions,
  StepWithPath,
} from '../step-navigation';
import { useStepsStore } from '../step-navigation/steps-store-provider';
import { getStepIdentifier } from '../step-navigation/utils/get-step-identifier';

interface PortalProgressionHandlerProps<TFormData extends AnyObject> {
  onSetPortalProgression?: ({
    currentStepIdentifier,
    formData,
    lastCompletedStepNumber,
  }: HandleSetPortalProgressionOptions<TFormData>) => Promise<void>;
  steps: StepWithPath<TFormData>[];
  stepsUrlBase: string;
}

export const PortalProgressionHandler = <TFormData extends AnyObject>({
  children,
  onSetPortalProgression,
  steps,
  stepsUrlBase,
}: PropsWithChildren<Readonly<PortalProgressionHandlerProps<TFormData>>>) => {
  const isAdminPortalPersistenceEnabled = useFeature('adminPortalPersistence');
  const navigate = useNavigate();

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { formData, setFormData } = useFormContext();
  const { currentStepNumber } = useStepsStore();

  const { portalProgression } = usePortalProgressionStore();

  const persistPortalProgression = useDebouncedCallback(
    (currentStepIdentifier, formData) => {
      if (!onSetPortalProgression) {
        return;
      }

      const currentStep = steps[currentStepNumber - 1];

      const lastCompletedStepNumber = getLastCompletedStepNumber(
        portalProgression,
        currentStepNumber,
      );

      void onSetPortalProgression({
        currentStep,
        currentStepIdentifier,
        formData,
        lastCompletedStepNumber,
      });
    },
    2000,
    {
      leading: true,
    },
  );

  const currentStep = steps[currentStepNumber - 1];

  const currentStepIdentifier = getStepIdentifier(
    currentStepNumber,
    currentStep?.title,
  );

  const firstStepIdentifier = useRef(currentStepIdentifier);

  useEffect(() => {
    if (!isAdminPortalPersistenceEnabled) {
      return;
    }

    if (!currentStepIdentifier) {
      return;
    }

    if (
      progressionHasChanged(
        portalProgression,
        currentStepIdentifier,
        formData,
        firstStepIdentifier.current,
      )
    ) {
      void persistPortalProgression(currentStepIdentifier, formData);
    }
  }, [
    currentStepIdentifier,
    currentStepNumber,
    formData,
    isAdminPortalPersistenceEnabled,
    persistPortalProgression,
    portalProgression,
  ]);

  useEffect(() => {
    if (isInitialLoad) {
      if (!isAdminPortalPersistenceEnabled || !portalProgression) {
        return;
      }

      setIsInitialLoad(false);

      const currentStepIndex = steps.findIndex(
        (step) =>
          step.stepIdentifier === portalProgression.currentStepIdentifier,
      );

      if (currentStepIndex === -1) {
        navigate(`${stepsUrlBase}/1`, { replace: true });

        return;
      }

      setFormData(portalProgression.formData);

      const currentStepNumber = currentStepIndex + 1;

      navigate(`${stepsUrlBase}/${currentStepNumber}`, { replace: true });
    }
  }, [
    isAdminPortalPersistenceEnabled,
    isInitialLoad,
    navigate,
    portalProgression,
    setFormData,
    steps,
    stepsUrlBase,
  ]);

  return <>{children}</>;
};

const getLastCompletedStepNumber = (
  portalProgression: PortalProgressionFragment | null | undefined,
  currentStepNumber: number,
): number => {
  if (!portalProgression) {
    return currentStepNumber;
  }

  if (currentStepNumber > portalProgression.lastCompletedStepNumber) {
    return currentStepNumber;
  }

  return portalProgression.lastCompletedStepNumber;
};

const progressionHasChanged = (
  portalProgression: PortalProgressionFragment | null | undefined,
  currentStepIdentifier: string,
  formData: AnyObject,
  firstStepIdentifier: string | undefined,
) => {
  const hasFormDataChanged =
    JSON.stringify(portalProgression?.formData || {}) !==
    JSON.stringify(formData);

  if (firstStepIdentifier === currentStepIdentifier && !hasFormDataChanged) {
    return false;
  }

  if (portalProgression?.currentStepIdentifier !== currentStepIdentifier) {
    return true;
  }

  if (hasFormDataChanged) {
    return true;
  }

  return false;
};
