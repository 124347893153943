import { ConnectionFragment } from '../../../../graphql/generated';
import {
  AnyObject,
  RecordStateDispatcher,
} from '../../../hooks/use-record-state';
import { PortalProgressionStore } from '../../../shared/portal-progression-provider';
import { graphql } from '../../../utils/graphql';

export const setConnectionPortalProgression = async <
  TFormData extends AnyObject,
>({
  connection,
  currentStepIdentifier,
  formData,
  lastCompletedStepNumber,
  setPortalProgressionStore,
}: {
  connection: ConnectionFragment;
  currentStepIdentifier: string;
  formData: TFormData;
  lastCompletedStepNumber: number;
  setPortalProgressionStore: RecordStateDispatcher<PortalProgressionStore>;
}): Promise<void> => {
  const { data } = await graphql().SetConnectionPortalProgression({
    input: {
      connectionId: connection.id,
      currentStepIdentifier,
      lastCompletedStepNumber,
      formData: Object.keys(formData).length > 0 ? formData : undefined,
    },
  });

  if (
    data?.portal_setConnectionPortalProgression.__typename ===
    'PortalProgressionSet'
  ) {
    setPortalProgressionStore({
      portalProgression:
        data.portal_setConnectionPortalProgression.portalProgression,
    });
  }
};
